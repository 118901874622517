// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.change-password {
  width: 300px;
}
.change-password .password-card {
  padding: 1rem;
  border: 2px solid red;
}`, "",{"version":3,"sources":["webpack://./src/components/changePassword/ChangePassword.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;AAAE;EACE,aAAA;EACA,qBAAA;AAEJ","sourcesContent":[".change-password {\r\n  width: 300px;\r\n  .password-card {\r\n    padding: 1rem;\r\n    border: 2px solid red;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
