// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Card_card__pqOY8 {
  border: 1px solid transparent;
  border-radius: 5px;
  box-shadow: var(--box-shadow);
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/components/card/Card.module.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,kBAAA;EACA,6BAAA;EACA,gBAAA;AACF","sourcesContent":[".card {\r\n  border: 1px solid transparent;\r\n  border-radius: 5px;\r\n  box-shadow: var(--box-shadow);\r\n  overflow: hidden;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `Card_card__pqOY8`
};
export default ___CSS_LOADER_EXPORT___;
