// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-box {
  width: 100%;
  height: 7rem;
  max-width: 22rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  vertical-align: middle;
  flex-wrap: wrap;
  color: #fff;
  transform: translateY(0);
  transition: all 0.3s;
}
.info-box:hover {
  cursor: pointer;
  transform: translateY(-5px);
}
.info-box .info-icon {
  padding: 0 2rem;
  color: #fff;
}
.info-box .info-text > * {
  color: #fff;
}

@media screen and (max-width: 600px) {
  .info-box {
    max-width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/infoBox/InfoBox.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;EACA,mBAAA;EACA,aAAA;EACA,2BAAA;EACA,mBAAA;EACA,sBAAA;EACA,eAAA;EACA,WAAA;EACA,wBAAA;EACA,oBAAA;AACF;AAGE;EACE,eAAA;EAEA,2BAAA;AAFJ;AAKE;EACE,eAAA;EACA,WAAA;AAHJ;AAKE;EACE,WAAA;AAHJ;;AAcA;EACE;IACE,eAAA;EAXF;AACF","sourcesContent":[".info-box {\r\n  width: 100%;\r\n  height: 7rem;\r\n  max-width: 22rem;\r\n  margin-right: 1rem;\r\n  margin-bottom: 1rem;\r\n  display: flex;\r\n  justify-content: flex-start;\r\n  align-items: center;\r\n  vertical-align: middle;\r\n  flex-wrap: wrap;\r\n  color: #fff;\r\n  transform: translateY(0);\r\n  transition: all 0.3s;\r\n\r\n  // background-color: green;\r\n\r\n  &:hover {\r\n    cursor: pointer;\r\n    // border: 3px solid #fff;\r\n    transform: translateY(-5px);\r\n  }\r\n\r\n  .info-icon {\r\n    padding: 0 2rem;\r\n    color: #fff;\r\n  }\r\n  .info-text > * {\r\n    color: #fff;\r\n  }\r\n\r\n  // .card {\r\n  //   border: 1px solid #ccc;\r\n  //   border-bottom: 3px solid var(--light-blue);\r\n  //   padding: 5px;\r\n  //   background-color: #f5f6fa;\r\n  // }\r\n}\r\n\r\n@media screen and (max-width: 600px) {\r\n  .info-box {\r\n    max-width: 100%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
