// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Search_search__PQsNp {
  margin: 5px 0;
  position: relative;
  flex: 1 1;
}
.Search_search__PQsNp .Search_icon__pJy84 {
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
}
.Search_search__PQsNp input[type=text] {
  display: block;
  font-size: 1.6rem;
  font-weight: 300;
  padding: 1rem;
  padding-left: 3rem;
  margin: 1rem auto;
  width: 100%;
  border: 1px solid #777;
  border-radius: 3px;
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/components/search/Search.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,SAAA;AACF;AACE;EACE,kBAAA;EACA,QAAA;EACA,UAAA;EACA,2BAAA;AACJ;AAEE;EACE,cAAA;EACA,iBAAA;EACA,gBAAA;EACA,aAAA;EACA,kBAAA;EACA,iBAAA;EACA,WAAA;EACA,sBAAA;EACA,kBAAA;EACA,aAAA;AAAJ","sourcesContent":[".search {\r\n  margin: 5px 0;\r\n  position: relative;\r\n  flex: 1;\r\n\r\n  .icon {\r\n    position: absolute;\r\n    top: 50%;\r\n    left: 1rem;\r\n    transform: translateY(-50%);\r\n  }\r\n\r\n  input[type=\"text\"] {\r\n    display: block;\r\n    font-size: 1.6rem;\r\n    font-weight: 300;\r\n    padding: 1rem;\r\n    padding-left: 3rem;\r\n    margin: 1rem auto;\r\n    width: 100%;\r\n    border: 1px solid #777;\r\n    border-radius: 3px;\r\n    outline: none;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search": `Search_search__PQsNp`,
	"icon": `Search_icon__pJy84`
};
export default ___CSS_LOADER_EXPORT___;
