// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-summary {
  width: 100%;
}
.product-summary .info-summary {
  display: flex;
  flex-wrap: wrap;
}

.card {
  border: 1px solid #ccc;
  padding: 5px;
  background-color: #f5f6fa;
}

.card1 {
  background-color: #b624ff;
}

.card2 {
  background-color: #32963d;
}

.card3 {
  background-color: #c41849;
}

.card4 {
  background-color: #03a5fc;
}`, "",{"version":3,"sources":["webpack://./src/components/product/productSummary/ProductSummary.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AAAE;EACE,aAAA;EACA,eAAA;AAEJ;;AAEA;EACE,sBAAA;EAEA,YAAA;EACA,yBAAA;AAAF;;AAGA;EACE,yBAAA;AAAF;;AAEA;EACE,yBAAA;AACF;;AACA;EACE,yBAAA;AAEF;;AAAA;EACE,yBAAA;AAGF","sourcesContent":[".product-summary {\r\n  width: 100%;\r\n  .info-summary {\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n  }\r\n}\r\n\r\n.card {\r\n  border: 1px solid #ccc;\r\n  // border-bottom: 3px solid var(--light-blue);\r\n  padding: 5px;\r\n  background-color: #f5f6fa;\r\n}\r\n\r\n.card1 {\r\n  background-color: #b624ff;\r\n}\r\n.card2 {\r\n  background-color: #32963d;\r\n}\r\n.card3 {\r\n  background-color: #c41849;\r\n}\r\n.card4 {\r\n  background-color: #03a5fc;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
