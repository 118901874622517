// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile .card {
  max-width: 700px;
  display: flex;
  justify-content: flex-start;
  padding: 1rem 0;
}
.profile .card span.profile-photo {
  text-align: center;
}
.profile .card img {
  width: 100%;
  max-width: 350px;
  padding-right: 1rem;
  padding-left: 1rem;
}
.profile .card span.profile-data {
  margin: 0 1rem;
}
.profile .card span.profile-data > * {
  border-top: 1px solid #ccc;
  padding: 5px 0;
}

@media screen and (max-width: 600px) {
  span.profile-photo {
    margin: auto;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/profile/Profile.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;EACA,aAAA;EACA,2BAAA;EAEA,eAAA;AADJ;AAGI;EACE,kBAAA;AADN;AAII;EACE,WAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;AAFN;AAII;EACE,cAAA;AAFN;AAII;EACE,0BAAA;EACA,cAAA;AAFN;;AAOA;EACE;IACE,YAAA;EAJF;AACF","sourcesContent":[".profile {\r\n  .card {\r\n    max-width: 700px;\r\n    display: flex;\r\n    justify-content: flex-start;\r\n\r\n    padding: 1rem 0;\r\n\r\n    span.profile-photo {\r\n      text-align: center;\r\n    }\r\n\r\n    img {\r\n      width: 100%;\r\n      max-width: 350px;\r\n      padding-right: 1rem;\r\n      padding-left: 1rem;\r\n    }\r\n    span.profile-data {\r\n      margin: 0 1rem;\r\n    }\r\n    span.profile-data > * {\r\n      border-top: 1px solid #ccc;\r\n      padding: 5px 0;\r\n    }\r\n  }\r\n}\r\n\r\n@media screen and (max-width: 600px) {\r\n  span.profile-photo {\r\n    margin: auto;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
