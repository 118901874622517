// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-product {
  margin-bottom: 5rem;
}
.add-product .card {
  width: 100%;
  max-width: 500px;
  padding: 1rem;
}
.add-product form label {
  display: block;
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--color-dark);
}
.add-product form input[type=text],
.add-product form input[type=number],
.add-product form input[type=file],
.add-product form input[type=email],
.add-product form select,
.add-product form input[type=password] {
  display: block;
  font-size: 1.6rem;
  font-weight: 300;
  padding: 1rem;
  margin: 1rem auto;
  width: 100%;
  border: 1px solid #777;
  border-radius: 3px;
  outline: none;
}

.group {
  border: 1px solid var(--dark-blue);
  padding: 5px;
}

.image-preview {
  width: 100%;
  height: 100%;
  max-height: 280px;
  background: var(--dark-blue);
  padding: 1rem;
  border-radius: 3px;
  overflow: hidden;
  transition: all 0.3s;
}

.image-preview img {
  width: 100%;
}

.image-show {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/components/product/productForm/ProductForm.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AAAE;EACE,WAAA;EACA,gBAAA;EACA,aAAA;AAEJ;AACI;EACE,cAAA;EACA,iBAAA;EACA,gBAAA;EACA,wBAAA;AACN;AACI;;;;;;EAME,cAAA;EACA,iBAAA;EACA,gBAAA;EACA,aAAA;EACA,iBAAA;EACA,WAAA;EACA,sBAAA;EACA,kBAAA;EACA,aAAA;AACN;;AAIA;EACE,kCAAA;EACA,YAAA;AADF;;AAIA;EACE,WAAA;EACA,YAAA;EACA,iBAAA;EACA,4BAAA;EACA,aAAA;EACA,kBAAA;EACA,gBAAA;EAEA,oBAAA;AAFF;;AAKA;EACE,WAAA;AAFF;;AAKA;EACE,cAAA;AAFF","sourcesContent":[".add-product {\r\n  margin-bottom: 5rem;\r\n  .card {\r\n    width: 100%;\r\n    max-width: 500px;\r\n    padding: 1rem;\r\n  }\r\n  form {\r\n    label {\r\n      display: block;\r\n      font-size: 1.4rem;\r\n      font-weight: 500;\r\n      color: var(--color-dark);\r\n    }\r\n    input[type=\"text\"],\r\n    input[type=\"number\"],\r\n    input[type=\"file\"],\r\n    input[type=\"email\"],\r\n    select,\r\n    input[type=\"password\"] {\r\n      display: block;\r\n      font-size: 1.6rem;\r\n      font-weight: 300;\r\n      padding: 1rem;\r\n      margin: 1rem auto;\r\n      width: 100%;\r\n      border: 1px solid #777;\r\n      border-radius: 3px;\r\n      outline: none;\r\n    }\r\n  }\r\n}\r\n\r\n.group {\r\n  border: 1px solid var(--dark-blue);\r\n  padding: 5px;\r\n}\r\n\r\n.image-preview {\r\n  width: 100%;\r\n  height: 100%;\r\n  max-height: 280px;\r\n  background: var(--dark-blue);\r\n  padding: 1rem;\r\n  border-radius: 3px;\r\n  overflow: hidden;\r\n  // display: none;\r\n  transition: all 0.3s;\r\n}\r\n\r\n.image-preview img {\r\n  width: 100%;\r\n}\r\n\r\n.image-show {\r\n  display: block;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
