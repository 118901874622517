// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-list {
  color: #333;
}
.product-list .table {
  padding: 5px;
  width: 100%;
  overflow-x: auto;
}
.product-list .table .search {
  width: 100%;
  max-width: 300px;
}
.product-list .table table {
  border-collapse: collapse;
  width: 100%;
  font-size: 1.4rem;
}
.product-list .table table thead {
  border-top: 2px solid var(--light-blue);
  border-bottom: 2px solid var(--light-blue);
}
.product-list .table table th {
  border: 1px solid #eee;
}
.product-list .table table th,
.product-list .table table td {
  vertical-align: top;
  text-align: left;
  padding: 8px;
}
.product-list .table table th.icons,
.product-list .table table td.icons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.product-list .table table th.icons > *,
.product-list .table table td.icons > * {
  margin-right: 7px;
  cursor: pointer;
  vertical-align: middle;
  align-self: center;
}
.product-list .table table tr {
  border-bottom: 1px solid #ccc;
}
.product-list .table table tr:nth-child(even) {
  background-color: #fff;
}
.product-list .table table tbody tr:hover {
  cursor: pointer;
  background-color: rgba(31, 147, 255, 0.3);
}`, "",{"version":3,"sources":["webpack://./src/components/product/productList/productList.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AAAE;EACE,YAAA;EACA,WAAA;EACA,gBAAA;AAEJ;AAAI;EACE,WAAA;EACA,gBAAA;AAEN;AACI;EACE,yBAAA;EACA,WAAA;EACA,iBAAA;AACN;AACM;EACE,uCAAA;EACA,0CAAA;AACR;AAEM;EACE,sBAAA;AAAR;AAGM;;EAEE,mBAAA;EACA,gBAAA;EACA,YAAA;AADR;AAGQ;;EACE,aAAA;EACA,2BAAA;EACA,mBAAA;AAAV;AACU;;EACE,iBAAA;EACA,eAAA;EACA,sBAAA;EACA,kBAAA;AAEZ;AAGM;EACE,6BAAA;AADR;AAIM;EACE,sBAAA;AAFR;AAKQ;EACE,eAAA;EACA,yCAAA;AAHV","sourcesContent":[".product-list {\r\n  color: #333;\r\n  .table {\r\n    padding: 5px;\r\n    width: 100%;\r\n    overflow-x: auto;\r\n\r\n    .search {\r\n      width: 100%;\r\n      max-width: 300px;\r\n    }\r\n\r\n    table {\r\n      border-collapse: collapse;\r\n      width: 100%;\r\n      font-size: 1.4rem;\r\n\r\n      thead {\r\n        border-top: 2px solid var(--light-blue);\r\n        border-bottom: 2px solid var(--light-blue);\r\n      }\r\n\r\n      th {\r\n        border: 1px solid #eee;\r\n      }\r\n\r\n      th,\r\n      td {\r\n        vertical-align: top;\r\n        text-align: left;\r\n        padding: 8px;\r\n\r\n        &.icons {\r\n          display: flex;\r\n          justify-content: flex-start;\r\n          align-items: center;\r\n          > * {\r\n            margin-right: 7px;\r\n            cursor: pointer;\r\n            vertical-align: middle;\r\n            align-self: center;\r\n          }\r\n        }\r\n      }\r\n\r\n      tr {\r\n        border-bottom: 1px solid #ccc;\r\n      }\r\n\r\n      tr:nth-child(even) {\r\n        background-color: #fff;\r\n      }\r\n      tbody {\r\n        tr:hover {\r\n          cursor: pointer;\r\n          background-color: rgba(31, 147, 255, 0.3);\r\n        }\r\n      }\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
