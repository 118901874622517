// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home {
  background-color: #030b6b;
  min-height: 100vh;
}
.home nav {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  color: #fff;
}
.home nav > .logo {
  color: #fff;
}

.home-links {
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-links li a {
  color: #fff;
}
.home-links > * {
  margin-left: 1rem;
  color: #fff;
}

.hero {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-text {
  width: 50%;
}
.hero-text > * {
  color: #fff;
  margin-bottom: 2rem;
}
.hero-text > h2 span {
  display: block;
}
.hero-text .hero-buttons a {
  color: #fff;
}

.hero-image {
  width: 50%;
}

@media screen and (max-width: 600px) {
  .hero {
    flex-direction: column;
  }
  .hero-text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin: auto;
    text-align: center;
  }
  .hero-image {
    width: 100%;
  }
  .hero-imageimg {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Home/Home.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,iBAAA;AACF;AACE;EACE,mBAAA;EACA,sBAAA;EACA,WAAA;AACJ;AAAI;EACE,WAAA;AAEN;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;AAAE;EACE,WAAA;AAEJ;AAAE;EACE,iBAAA;EACA,WAAA;AAEJ;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AAEA;EACE,UAAA;AACF;AAAE;EACE,WAAA;EACA,mBAAA;AAEJ;AAAE;EACE,cAAA;AAEJ;AAAE;EACE,WAAA;AAEJ;;AACA;EACE,UAAA;AAEF;;AAAA;EACE;IACE,sBAAA;EAGF;EAAA;IACE,aAAA;IACA,uBAAA;IACA,mBAAA;IACA,sBAAA;IACA,WAAA;IACA,YAAA;IACA,kBAAA;EAEF;EAAA;IACE,WAAA;EAEF;EADE;IACE,WAAA;EAGJ;AACF","sourcesContent":[".home {\r\n  background-color: #030b6b;\r\n  min-height: 100vh;\r\n\r\n  & nav {\r\n    padding-top: 1.5rem;\r\n    padding-bottom: 1.5rem;\r\n    color: #fff;\r\n    & > .logo {\r\n      color: #fff;\r\n    }\r\n  }\r\n}\r\n.home-links {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  & li a {\r\n    color: #fff;\r\n  }\r\n  & > * {\r\n    margin-left: 1rem;\r\n    color: #fff;\r\n  }\r\n}\r\n\r\n.hero {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n\r\n.hero-text {\r\n  width: 50%;\r\n  & > * {\r\n    color: #fff;\r\n    margin-bottom: 2rem;\r\n  }\r\n  & > h2 span {\r\n    display: block;\r\n  }\r\n  & .hero-buttons a {\r\n    color: #fff;\r\n  }\r\n}\r\n.hero-image {\r\n  width: 50%;\r\n}\r\n@media screen and (max-width: 600px) {\r\n  .hero {\r\n    flex-direction: column;\r\n  }\r\n\r\n  .hero-text {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    flex-direction: column;\r\n    width: 100%;\r\n    margin: auto;\r\n    text-align: center;\r\n  }\r\n  .hero-image {\r\n    width: 100%;\r\n    &img {\r\n      width: 100%;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
