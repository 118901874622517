// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-detail {
  margin-bottom: 5rem;
  width: 100%;
  max-width: 500px;
}
.product-detail .card {
  width: 100%;
  max-width: 500px;
  padding: 1rem;
}
.product-detail .detail > * {
  margin-bottom: 1rem;
}
.product-detail .detail .badge {
  background-color: var(--color-danger);
  color: #fff;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 3px;
}
.product-detail .detail p b {
  color: var(--dark-blue);
}

.group {
  border: 1px solid var(--dark-blue);
  padding: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/product/productDetail/ProductDetail.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,WAAA;EACA,gBAAA;AACF;AAAE;EACE,WAAA;EACA,gBAAA;EACA,aAAA;AAEJ;AACI;EACE,mBAAA;AACN;AACI;EACE,qCAAA;EACA,WAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;AACN;AACI;EACE,uBAAA;AACN;;AAIA;EACE,kCAAA;EACA,YAAA;AADF","sourcesContent":[".product-detail {\r\n  margin-bottom: 5rem;\r\n  width: 100%;\r\n  max-width: 500px;\r\n  .card {\r\n    width: 100%;\r\n    max-width: 500px;\r\n    padding: 1rem;\r\n  }\r\n  .detail {\r\n    & > * {\r\n      margin-bottom: 1rem;\r\n    }\r\n    .badge {\r\n      background-color: var(--color-danger);\r\n      color: #fff;\r\n      padding-left: 2px;\r\n      padding-right: 2px;\r\n      border-radius: 3px;\r\n    }\r\n    p b {\r\n      color: var(--dark-blue);\r\n    }\r\n  }\r\n}\r\n\r\n.group {\r\n  border: 1px solid var(--dark-blue);\r\n  padding: 5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
